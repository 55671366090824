import usersNotAssignedCampaign from '@/graphql/queries/users/usersNotAssignedCampaign.gql';
import totalLicens from '@/graphql/queries/users/totalAdminSuperByCompany.gql';
import executeQuery from '@/utils/gql-api';

function fetchUnassignedUsers(page, companyId, campaignId, length, text, role) {
  return executeQuery(
    'usersNotAssignedCampaign', usersNotAssignedCampaign,
    {
      page,
      companyId,
      campaignId,
      length,
      text,
      role,
    },
    false,
  );
}

async function totalAdminSuper(company) {
  const total = await executeQuery(
    'totalLicens',
    totalLicens, {
      company,
    },
  );
  return total;
}

export {
  fetchUnassignedUsers,
  totalAdminSuper,
};
